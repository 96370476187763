import React from 'react';
import { MapPin, Phone } from 'lucide-react';
import './harta.css';

export const LocationCard = ({ location, id }) => {
  return (
    <div className="location-card" id={id}>
      {/* Tipul locației (Clinic sau Partener) */}
      <div className={`location-type ${location.type === 'clinic' ? 'clinic' : 'partner'}`}>
        {location.type === 'clinic' ? 'Cabinet Audiogram' : 'Cabinet Partener'}
      </div>

      {/* Container pentru date și hartă pe aceeași linie */}
      <div className="location-content">
        {/* Informațiile locației */}
        <div className="location-details">
          <h3 className="location-title">{location.city}</h3>

          {/* Adresa */}
          <div className="location-info">
            <MapPin className="icon" />
            <span className="location-address">{location.address}</span>
          </div>

          {/* Telefon - afișat doar dacă există */}
          {location.phone && (
            <div className="location-info">
              <Phone className="icon" />
              <a href={`tel:${location.phone}`} className="location-phone">
                {location.phone}
              </a>
            </div>
          )}
        </div>

        {/* Google Maps Iframe - afișat doar dacă există */}
        {location.MapIframe && (
          <div className="map-container" dangerouslySetInnerHTML={{ __html: location.MapIframe }} />
        )}
      </div>
    </div>
  );
};

