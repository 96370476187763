import React, { Component } from 'react';

class Locatii extends Component {
    constructor() {
        super();
        this.state = {
            locatii: []
        };
    }

    componentDidMount() {
        fetch("./locatii.json")
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    locatii: data.locatii,
                }, () => {
                    const hash = window.location.hash;
                    if (hash) {
                        const element = document.getElementById(hash.substring(1));
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                    }
                });
            });
    }

    handlePhoneClick = () => {
        if (typeof window !== 'undefined' && window.fbq) {
            window.fbq('track', 'Contact');
        } else {
            console.log("Meta Pixel nu este încărcat sau fbq nu este definit");
        }
    }


    render() {
        let order = 1;

        return (
            <div>
                <section className="internal-page-wrapper">
                    <div className="row justify-content-center text-center locatii-section">
                    </div>
                    <div className="container">
                        {
                            this.state.locatii.map((locatie) => {
                                let id = locatie.Id;

                                let order1 = 1;
                                let order2 = 2;
                                if (order !== 1) {
                                    order1 = 2;
                                    order2 = 1;
                                    order--;
                                } else {
                                    order++;
                                }

                                return (
                                    <div key={id} id={id} className="row justify-content-center text-center product-holder h-100 locatie">
                                        <div className={`col-sm-12 col-md-6 my-auto order-${order1}`}>
                                            <div className="product-title">{locatie.Title}</div>
                                            <div className="locatie-telefon">
                                                <a
                                                    href={`tel:${locatie.Telefon}`}
                                                    onClick={this.handlePhoneClick}
                                                >
                                                    {locatie.Telefon}
                                                </a>
                                            </div>
                                            <div className="monthly-price inch">{locatie.Adresa}</div>
                                            <div className="description-wraper">{locatie.Brief}</div>
                                            <li className='programare'>
                                                <a href="https://business.audiogram.ro/programari">Fă o programare</a>
                                            </li>
                                        </div>
                                        <div className={`col-sm-12 col-md-6 order-${order2}`}>
                                            <div className="map-iframe" dangerouslySetInnerHTML={{ __html: locatie.MapIframe }} />
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </section>
            </div>
        );
    }
}

export default Locatii;
