export const locations = [
    {
      id: 1,
      city: "Cluj-Napoca",
      county: "RO-CJ",
      address: "Str. Moților nr. 14",
      type: "clinic",
      phone: "0747 046 056",
      MapIframe: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2732.831545100854!2d23.5837664!3d46.7682168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47490e8314355155%3A0x8657bb6c482d7305!2sAudiogram!5e0!3m2!1sen!2sro!4v1706643140678!5m2!1sen!2sro" 
      width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade"></iframe>`
      },
    {
      id: 2,
      city: "Cluj-Napoca",
      county: "Cluj",
      address: "Str. Moților nr. 14",
      type: "clinic",
      phone: "0747 046 056",
      MapIframe: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2732.831545100854!2d23.5837664!3d46.7682168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47490e8314355155%3A0x8657bb6c482d7305!2sAudiogram!5e0!3m2!1sen!2sro!4v1706643140678!5m2!1sen!2sro" 
      width="100%" height="100" style="border:0;" allowfullscreen="" loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade"></iframe>`
    },
    {
      id: 3,
      city: "Deva",
      county: "Hunedoara",
      address: "Str. Cernei, nr.11",
      type: "clinic",
      phone: "0773 801 809"
    },
    {
      id: 4,
      city: "Petrosani",
      county: "Hunedoara",
      address: "Str. 1 Decembrie 1918, nr.135",
      type: "clinic",
      phone: "0771 053 352"
    },
    {
      id: 5,
      city: "Petrosani",
      county: "Hunedoara",
      address: "Str. Păcurari nr. 25",
      type: "partner",
      phone: "0757 106 109"
    },
    {
      id: 6,
      city: "Arad",
      county: "Arad",
      address: "Str. Mețianu nr.1",
      type: "clinic",
      phone: "0738 212 001"
    },
    {
      id: 7,
      city: "Arad",
      county: "Arad",
      address: "Str. Eftimie Murgu nr.63",
      type: "clinic",
      phone: "0770 801 333"
    }, 
    {
      id: 8,
      city: "Oradea",
      county: "Bihor",
      address: "Bd. Dacia nr.42",
      type: "clinic",
      phone: "0770 801 222"
    }, 
    {
      id: 9,
      city: "Baia Mare",
      county: "Maramures",
      address: "Bd. Regele Mihai I nr.3A",
      type: "clinic",
      phone: "0773 801 800"
    },
    {
      id: 10,
      city: "Timisoara",
      county: "Timisoara",
      address: "Bd. Regele Mihai I nr.3A",
      type: "clinic",
      phone: "0772 006 295"
    }, 
    {
      id: 11,
      city: "Timisoara",
      county: "Timisoara",
      address: "Bd. Revoluției din 1989 nr.16-18",
      type: "clinic",
      phone: "0773 801 801"
    }
  ];