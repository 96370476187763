import React, {Component } from 'react';
import './FourthContaints.css'
import StructureTwo from '../Containts/StructureTwo'

class ForthContaint extends Component {
    render() { 
        return ( 
            <section className="fourth-heghlight-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="left-side-wrapper col-sm-12 col-md-6">
                            <div className="left-side-container">
                                <div className="top-logo-wrapper">
                                    <div className="title-wraper clienti">
                                        Zona clienti
                                    </div>
                                </div>

                                <div className="price-wrapper clienti">
                                Un spațiu pentru excelență
                                </div>

                                <div className="links-wrapper">
                                    <ul>
                                        <li><a href="/zona-clienti">Afla mai multe</a></li>
                                    </ul> 
                                </div>
                            </div>
                        </div>
                        <div className="right-side-wrapper col-sm-12 col-md-6">
                            <div className="right-side-container">
                                <div className="top-logo-wrapper">
                                    <div className="title-wraper clienti">
                                        Programare
                                    </div>
                                </div>

                                <div className="price-wrapper clienti">
                                    Teste Auditive Prietenoase
                                </div>

                                <div className="links-wrapper">
                                    <ul>
                                        <li><a href="https://business.audiogram.ro/programari">Afla mai multe</a></li>
                                    </ul> 
                                </div>
                            </div>
                        </div>					
                    </div>
                </div> 
	        </section>
         );
    }
}
 
export default ForthContaint;