import React, { Component } from 'react';

class PoliticaDeConfidentialitate extends Component {
    render() {
        return (
            <div>
                <section className="internal-page-wrapper">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-12">
                                <div className="title-wraper large">
                                    Politica de Confidențialitate
                                </div>

                                <div className="zona-description">
                                    <strong>1. Informații generale</strong>
                                    <br />
                                    Prezenta Politică de Confidențialitate stabilește modul în care S.C. AUDIOGRAM SRL, cu sediul în Localitatea Cluj-Napoca, Județul Cluj, înregistrată la Registrul Comerțului cu nr. J12/3799/2015 și având CUI RO 35336888 (denumită în continuare „Audiogram”, „noi” sau „site-ul”), colectează, utilizează, stochează și protejează datele personale ale vizitatorilor site-ului nostru. Rețineți că site-ul Audiogram este destinat exclusiv prezentării aparatelor auditive și nu oferă funcționalități de comerț electronic.
                                </div>

                                <div className="zona-description">
                                    <strong>2. Datele personale colectate</strong>
                                    <br />
                                    AudioGram poate colecta și prelucra următoarele categorii de date personale:
                                    <br /><br />
                                    - <em>Date de identificare</em> (ex.: nume, prenume, adresă de e-mail, număr de telefon) atunci când acestea sunt furnizate voluntar prin intermediul formularelor de contact;
                                    <br />
                                    - <em>Date tehnice și de navigare</em> (ex.: adresa IP, tipul de browser, paginile vizitate, data și ora vizitei) prin intermediul cookie-urilor și a altor tehnologii similare.
                                </div>

                                <div className="zona-description">
                                    <strong>3. Modalități de colectare a datelor</strong>
                                    <br />
                                    Colectarea datelor se realizează prin:
                                    <br /><br />
                                    - Completarea directă a formularelor de contact sau a altor formulare disponibile pe site;
                                    <br />
                                    - Colectarea automată prin cookie-uri și tehnologii similare, pentru a analiza traficul și a îmbunătăți experiența de navigare.
                                </div>

                                <div className="zona-description">
                                    <strong>4. Scopul colectării și prelucrării datelor</strong>
                                    <br />
                                    Datele personale sunt colectate și prelucrate în scopuri legitime, cum ar fi:
                                    <br /><br />
                                    - Răspunsul la solicitările și întrebările adresate de vizitatori;
                                    <br />
                                    - Furnizarea de informații suplimentare legate de produsele și serviciile noastre (aparat auditive);
                                    <br />
                                    - Analiza statistică a traficului pe site, pentru optimizarea conținutului și a experienței de navigare;
                                    <br />
                                    - Respectarea obligațiilor legale.
                                </div>

                                <div className="zona-description">
                                    <strong>5. Baza legală a prelucrării datelor</strong>
                                    <br />
                                    Prelucrarea datelor personale se realizează pe baza:
                                    <br /><br />
                                    - Consimțământului explicit al persoanelor vizate, atunci când este solicitat;
                                    <br />
                                    - Necesității executării unui contract sau pentru a răspunde unei cereri a utilizatorilor (ex.: solicitare de informații);
                                    <br />
                                    - Obligațiilor legale impuse de autoritățile competente;
                                    <br />
                                    - Intereselor legitime ale AudioGram, cu respectarea drepturilor și libertăților fundamentale ale persoanelor vizate.
                                </div>

                                <div className="zona-description">
                                    <strong>6. Stocarea și securitatea datelor</strong>
                                    <br />
                                    AudioGram se angajează să asigure un nivel adecvat de securitate pentru datele personale, prin implementarea unor măsuri tehnice și organizatorice corespunzătoare. Datele sunt stocate pe servere securizate, iar accesul la acestea este limitat personalului autorizat.
                                </div>

                                <div className="zona-description">
                                    <strong>7. Transferul datelor</strong>
                                    <br />
                                    Datele personale nu vor fi transferate către terți, cu excepția cazurilor în care:
                                    <br /><br />
                                    - Transferul este necesar pentru prestarea unor servicii tehnice (ex.: servicii de găzduire, analiză a traficului) și se asigură că terții respectă cerințele legale privind protecția datelor;
                                    <br />
                                    - Există obligații legale care impun un astfel de transfer;
                                    <br />
                                    - Utilizatorul și-a dat consimțământul explicit.
                                </div>

                                <div className="zona-description">
                                    <strong>8. Cookie-uri și tehnologii similare</strong>
                                    <br />
                                    Site-ul utilizează cookie-uri pentru a:
                                    <br /><br />
                                    - Îmbunătăți experiența de navigare;
                                    <br />
                                    - Analiza comportamentul utilizatorilor și a traficului pe site;
                                    <br />
                                    - Personaliza conținutul, acolo unde s-a obținut acordul explicit al utilizatorului.
                                    <br />
                                    Utilizatorii pot gestiona preferințele privind cookie-urile prin setările browser-ului, ținând cont că dezactivarea acestora poate afecta funcționarea optimă a site-ului.
                                </div>

                                <div className="zona-description">
                                    <strong>9. Drepturile persoanelor vizate</strong>
                                    <br />
                                    Conform legislației în vigoare, aveți următoarele drepturi:
                                    <br /><br />
                                    - Dreptul de acces la datele personale prelucrate;
                                    <br />
                                    - Dreptul de rectificare a datelor inexacte sau incomplete;
                                    <br />
                                    - Dreptul de ștergere a datelor („dreptul de a fi uitat”), în condițiile prevăzute de lege;
                                    <br />
                                    - Dreptul de restricționare a prelucrării;
                                    <br />
                                    - Dreptul la portabilitatea datelor;
                                    <br />
                                    - Dreptul de a vă opune prelucrării datelor în anumite situații.
                                </div>

                                <div className="zona-description">
                                    <strong>10. Modalitatea de exercitare a drepturilor</strong>
                                    <br />
                                    Pentru a vă exercita drepturile, vă puteți adresa prin:
                                    <br /><br />
                                    - Email: [introduceți adresa de e-mail de contact];
                                    <br />
                                    - Corespondență la sediul nostru:
                                    <br />
                                    S.C. AUDIOGRAM SRL
                                    <br />
                                    Localitatea Cluj-Napoca, Județul Cluj
                                    <br />
                                    Cod poștal: [introduceți codul poștal, dacă este disponibil].
                                </div>

                                <div className="zona-description">
                                    <strong>11. Modificări ale Politicii de Confidențialitate</strong>
                                    <br />
                                    AudioGram își rezervă dreptul de a modifica această politică ori de câte ori este necesar, pentru a reflecta schimbările legislative sau modificările în practicile de prelucrare a datelor. Orice modificare semnificativă va fi publicată pe site.
                                </div>

                                <div className="zona-description">
                                    <strong>12. Contact</strong>
                                    <br />
                                    Pentru orice întrebări sau clarificări referitoare la prelucrarea datelor personale, vă rugăm să ne contactați la:
                                    <br /><br />
                                    - Email: hello@audiogram.ro
                                    <br />
                                    - Telefon: 0747 046 056
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default PoliticaDeConfidentialitate;
