import React, { Component } from 'react'
import './Termeni.css';

class Termeni extends Component {
    render() { 
                
        return ( 
            <div>
                <section className="internal-page-wrapper">
                        <div className="container"> 
                            <div className="row justify-content-center text-center">
                                        <div className="col-12">
                                                <div className="title-wraper large">
                                                Termeni si conditii
                                                </div>
                                                    <div className="zona-description">
                                                        Vizitarea, folosirea, sau comandarea produselor vizualizate pe site-ul <strong>www.audiogram.ro</strong> implica acceptarea Conditiilor de utilizare ce vor fi detaliate mai jos. Pentru folosirea in bune conditii a site-ului, se recomanda citirea cu atentie a Termenilor si Conditiilor.  								
                                                    </div>
                                                    <div className="zona-description">
                                                        Vizitarea, folosirea, sau comandarea produselor vizualizate pe site-ul www.audiogram.ro implica acceptarea Conditiilor de utilizare ce vor fi detaliate mai jos. Pentru folosirea in bune conditii a site-ului, se recomanda citirea cu atentie a Termenilor si Conditiilor.
                                                    <strong>www.audiogram.ro</strong> este administrat de S.C. AUDIOGRAM SRL cu sediul in Localitatea Cluj-Napoca, Jud. Cluj, inregistrata la Registrul Comertului cu nr J12/3799/2015, CUI RO 35336888 . Ne rezervam dreptul de a efectua modificari ale acestor Termeni si Conditii precum si orice modificari site-ului <strong>www.audiogram.ro</strong> fara o notificare prealabila. Accesand pagina Termeni si Conditii, puteti citi intotdeauna cea mai recenta versiune a acestor prevederi. 
                                                    </div> 
                                                    <div className="zona-description">
                                                        <strong>www.audiogram.ro</strong> sustine campania de combatere a fraudei prin revanzare, impreuna cu alte site-uri de profil. Ne rezervam dreptul de a respinge comenzi catalogate in sistemul intern ca si achizitie cu potential fraudulos. 
                                                    </div>
                                                    <h3 className="zona-description">
                                                        Obligatiile dumneavoastra de inregistrare:
                                                    </h3>
                                                    <div className="zona-description">
                                                        Pentru a putea cumpara de pe <strong>www.audiogram.ro</strong> trebuie sa introduceti datele dumneavoastra pe website-ul nostru. In utilizarea serviciului, declarati ca veti furniza informatii reale, corecte, actuale si complete despre dumneavoastra. In situatia in care consideram ca aceasta obligatie a fost incalcata, ne rezervam dreptul de a va bloca  accesul la utilizarea serviciului, pe perioada determinata sau nedeterminata, fara nicio notificare prealabila. 
                                                    </div>
                                                    <div className="zona-description">
                                                        Drepturile de autor (Copyright)  Intregul continut al site-ului <strong>www.audiogram.ro</strong>, incluzand, texte, imagini, grafice, elemente de grafica web, scripturi si orice alte date, este proprietatea S SC. AUDIOGRAM S.R.L. sau a furnizorilor sai si este protejat conform Legii drepturilor de autor si legilor privind dreptul de proprietate intelectuala si industriala. Folosirea fara acordul scris a oricaror elemente de pe site-ul <strong>www.audiogram.ro</strong>, sau a oricaror elemente enumerate mai sus se pedepseste conform legilor in vigoare. Pentru a reclama drepturile de proprietate intelectuala puteti folosi adresa de mail office@audio-gram.ro.                                                    
                                                    </div>
                                                    <div className="zona-description">
                                                        Responsabilitati privind produsele  S.C. AUDIOGRAM S.R.L. nu isi asuma responsabilitatea si nu poate fi facuta responsabila pentru orice pagube aparute prin folosirea produselor achizitionate prin intermediul acestui site, in alte scopuri decat cele mentionate de producator. Returnarea produselor se poate efectua in conformitate cu dispozitiile OG 130/2000.                                                    
                                                    </div>
                                                    <h3 className="zona-description">
                                                        Limitari privind utilizarea anumitor tehnici de comunicatie la distanta
                                                    </h3>
                                                    <div className="zona-description">
                                                    Art. 1. – Urmatoarele tehnici de comunicatie la distanta necesita acordul prealabil al consumatorului:
                                                        a) sistem automatizat de apel fara interventie umana (automat de apel);
                                                        b) telecopiator (fax)
                                                        c) adresa de posta electronica (e-mail)                                                    
                                                    </div> 
                                                    <div className="zona-description">
                                                        Art. 2. – Utilizarea altor tehnici de comunicatie individuala la distanta in afara celor prevazute la art. 1 nu este permisa daca exista un refuz manifestat de consumator.                                                    
                                                    </div> 
                                                    <h3 className="zona-description">
                                                        Lista cuprinzand tehnicile de comunicatie la distanta:
                                                    </h3> 
                                                    <div className="zona-description">
                                                        a) imprimat neadresat;
                                                        b) imprimat adresat;
                                                        c) scrisoare tipizata;
                                                        d) publicitate tiparita cu bon de comanda;
                                                        e) catalog;
                                                        f) telefon cu interventie umana;
                                                        g) telefon fara interventie umana (automat de apel, audiotext);
                                                        h) radio;
                                                        i) videofon (telefon cu imagine);
                                                        j) videotext (microordinator, ecran Tv cu tastatura sau ecran tactil);
                                                        k) posta electronica (e-mail);
                                                        l) telecopiator (fax);
                                                        m) televiziune (teleshopping).                                                    
                                                    </div> 
                                                    <h3 className="zona-description">
                                                        Litigii
                                                    </h3> 
                                                    <div className="zona-description">
                                                        Prin folosirea,vizualizarea sau cumpararea produselor de pe acest site, utilizatorul a luat la cunostinta asupra faptului ca legile romane vor guverna Termenii si Conditiile de mai sus si orice disputa de orice fel care ar putea sa apara intre utilizator si  S.C. AUDIOGRAM S.R.L. In cazul unor eventuale conflicte intreS.C. AUDIOGRAM S.R.L. si clientii sai, se va incerca mai intai rezolvarea acestora pe cale amiabila in minim 30 de zile lucratoare. Daca rezolvarea pe cale amiabila nu va fi posibila, conflictul va fi solutionat in instanta competenta in conformitate cu legile romane in vigoare.                                                   
                                                    </div>
                                                    <h3 className="zona-description">
                                                        Diverse
                                                    </h3>
                                                    <div className="zona-description">
                                                        Daca oricare dintre clauzele de mai sus va fi gasita nula sau nevalida, indiferent de cauza, aceasta nu va afecta valabilitatea celorlalte clauze.                                                    
                                                    </div>   
                                                    <div className="zona-description">
                                                        Odata cu lansarea comenzii, clientul accepta fara obiectiuni Conditiile si termenii de utilizare, valoarea acestora fiind aceeasi cu un contract valabil incheiat.  Fiind de acord cu acesti Termeni si Conditii clientul isi isi asuma in totalitate drepturile si obligatiile ce decurg din cumpararea din magazinul virtual <strong>www.audiogram.ro</strong>.                                                    
                                                    </div>      
                                            </div>
                                        </div>
                        </div>
                </section>
            </div>
            
            );     
        }
    }
 
export default Termeni;
