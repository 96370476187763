import React, { Component } from 'react';

class CookiePolicy extends Component {
    render() {
        return (
            <div>
                <section className="internal-page-wrapper">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-12">
                                <div className="title-wraper large">
                                    Politica de Cookie-uri
                                </div>
                                <div className="zona-description">
                                    Prezenta Politica de Cookie-uri explică modul în care S.C. AUDIOGRAM SRL, cu sediul în Localitatea Cluj-Napoca, Județul Cluj, înregistrată la Registrul Comerțului cu nr. J12/3799/2015 și având CUI RO 35336888 (denumită în continuare „Audiogram”, „noi” sau „site-ul”), utilizează cookie-uri și tehnologii similare pentru a îmbunătăți experiența utilizatorilor pe site-ul nostru de prezentare a aparatelor auditive.
                                </div>
                                <div className="zona-description">
                                    <strong>Ce sunt cookie-urile:</strong> Cookie-urile sunt fișiere mici de text stocate pe dispozitivul dumneavoastră (computer, tabletă, smartphone) atunci când vizitați site-ul. Acestea permit reținerea unor informații legate de vizita dumneavoastră, facilitând o navigare mai eficientă și o experiență personalizată.
                                </div>
                                <div className="zona-description">
                                    <strong>Tipuri de cookie-uri utilizate:</strong>
                                    <ul>
                                        <li>
                                            <strong>Cookie-uri esențiale:</strong> Sunt necesare pentru funcționarea corectă a site-ului și nu pot fi dezactivate.
                                        </li>
                                        <li>
                                            <strong>Cookie-uri de performanță:</strong> Colectează informații anonime despre modul în care vizitatorii folosesc site-ul pentru a îmbunătăți experiența de navigare.
                                        </li>
                                        <li>
                                            <strong>Cookie-uri de funcționalitate:</strong> Rețin alegerile efectuate de utilizatori (ex.: limba preferată) și permit personalizarea conținutului.
                                        </li>
                                        <li>
                                            <strong>Cookie-uri de publicitate:</strong> Folosite pentru a afișa anunțuri relevante, acestea fiind setate de terți și colectând informații anonime despre comportamentul de navigare.
                                        </li>
                                    </ul>
                                </div>
                                <div className="zona-description">
                                    <strong>Gestionarea cookie-urilor:</strong> Aveți posibilitatea de a accepta sau refuza cookie-urile. Deși majoritatea browserelor sunt configurate implicit pentru a accepta cookie-urile, puteți modifica setările acestora conform instrucțiunilor producătorilor de browser. Vă rugăm să rețineți că dezactivarea cookie-urilor poate afecta funcționarea optimă a site-ului.
                                </div>
                                <div className="zona-description">
                                    <strong>Cookie-uri de la terți:</strong> În anumite cazuri, site-ul poate utiliza cookie-uri provenind de la terți (ex.: furnizori de servicii de analiză a traficului sau platforme de publicitate). Informațiile colectate prin aceste cookie-uri sunt utilizate exclusiv în scopuri statistice și pentru a oferi conținut publicitar relevant.
                                </div>
                                <div className="zona-description">
                                    <strong>Modificări ale Politicii de Cookie-uri:</strong> AudioGram își rezervă dreptul de a modifica această politică în orice moment, pentru a reflecta schimbările legislative sau modificările în practicile de prelucrare a datelor. Orice modificare semnificativă va fi publicată pe site.
                                </div>
                                <div className="zona-description">
                                    <strong>Contact:</strong> Pentru orice întrebări sau nelămuriri privind utilizarea cookie-urilor, vă rugăm să ne contactați la:
                                    <ul>
                                        <li>Email: hello@audiogram.ro</li>
                                        <li>Telefon: 0747 046 056</li>
                                        <li>
                                            Adresă: S.C. AUDIOGRAM SRL, Calea Moților nr. 14 , Municipiul Cluj-Napoca, Județul Cluj 
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default CookiePolicy;
