import React, { Component } from "react"


import './Footer.css'
import './Toggel.js'

class Footer extends Component {
    render() {

        return (
            <footer className="footer-wrapper">
                <div className="container">
                    <div className="audiogram-wrapper">
                        Mai multe moduri de a ne găsi:
                        <a href="harta"> Găsiți un cabinet Audiogram</a> sau alt
                        <a href="harta"> colaborator</a> lângă tine.
                        Sau suna la <span className="phone-number">0747 046 056</span>.
                    </div>

                    <div className="copyright-wrapper">
                        <div className="footer-links-terms">
                            <ul>
                                <li><a href="politica-cookies">Politica cookies</a></li>
                                <li><a href="termeni-si-conditii">Termeni și condiții</a></li>
                                <li><a href="politica-de-confidentialitate">Politica de confidențialitate</a></li>
                                <li><a href="https://anpc.ro/" target="_blank" rel="noreferrer">Protecția consumatorului</a></li>
                            </ul> 
                        </div>
                    </div>

                    <div className="copyright">
                        Copyright &copy; {new Date().getFullYear()} Audiogram SRL. All rights reserved.
                    </div>
                </div>
            </footer>
        );
    }
}
export default Footer;